.bubble {
    /* background-color: rgb(0, 132, 255); */
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.campaign-sms {
    color: white;
    font-size: 24px;
}

.campaign-envelope {
    color: white;
    font-size: 24px;
}

.cog {
    path {
        color: white;
    }
}

#auto-export-tooltip {
    margin-left: 5px;
}

/* .campaign-settings:hover > .campaign-cog {
    transform: rotate(90deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transition: transform .5s;
} */

/* .campaign-settings > .campaign-cog {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transition: transform .5s;
} */