.prospectPage{
    width:90vw;
    margin:0px auto;
}

/* Header */
.prospectPage .header {
    display:flex;
    justify-content:space-between;
    flex-direction:row;
    border-bottom:solid 2px #eee;
    padding:40px 20px;
}

.prospectHeaderIcon{
    color:#e9e9e9;
    margin:0px 20px 0px 0px;
}

.prospectHeaderDetails{
    color:#38AFFC;
}

.prospectHeaderDetails div{
    margin-bottom:10px;
}


.prospectHeaderDetails svg{
    font-size:20px;
    border-radius:50%;
}

.header  .date {
    color:#38AFFC;
}


/* .campaign-dates div{
    width:400px;
    text-align:right;
} */



/* Messaging section */

.messages{
    display:flex;
    flex-direction:column;
    padding:40px 0px;
}

.messages .msgContainerTeamMember{
    align-self: flex-end;
}

.messages .msgContainerProspect{
    align-self: flex-start;
}

.message{
    display:flex;

    font-size:16px;
    margin-bottom:20px;
}

.dateTeamMember{
    text-align:right;
    padding-right:15px;
}

.dateProspect{
    text-align:left;
    padding-left:15px;
}


.TeamMember .messageTypeContainer{
    width:100px;
    background-color:#38AFFC;
    padding:20px;
    color:white;
    font-size:45px;

    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px 0px 0px 15px;
}

.Prospect .messageTypeContainer{
    width:100px;
    background-color:#595959;
    padding:20px;
    color:white;
    font-size:45px;

    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 15px 15px 0px;
}



.TeamMember .content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color:#387efc;
    color:white;
    padding:20px;
    border-radius:0px 15px 15px 0px;
    min-height:150px;
    width: 100%;
}

.Prospect .content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color:#e9e9e9;
    color:black;
    padding:20px;
    border-radius:15px 0px 0px 15px;
    min-height:150px;
    width: 100%;
}

.modalSubmitButtonContainer {
    margin-bottom: 0px;
}

.modalSubmitButton {
    width: 150px;
    font-size: 24px;
}


/* the style below is for src/components/Prospects/SendSMSModal.js */
.smsModalTextarea:focus{
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
}
/* end style for src/components/Prospects/SendSMSModal.js */