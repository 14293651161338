:root{
    --primary-color: #38AFFC;
}

#loginPage {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background-color: var(--primary-color);
}

#loginContainer {
    height: 85vh;
    width: 70vw;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#loginLogo {
    width: auto;
    height: 30%;
    margin:0px auto;
    margin-bottom: 60px;    
}

#loginForm {
    /*height: 200px; */
    width: 550px;
    background-color: white;
    border-radius: 2px;
    padding: 20px;
}

#loginBtn {
    width: 100%;
    color: white;
    border: none;
    border-radius: 2px;
    background-color: var(--primary-color);
}

.loginButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
