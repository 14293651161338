.campaign-touchpoint {
    min-height: 300px;
    flex-wrap: wrap;
}

.campaign-touchpoint .campaign-touchpoint-template textarea,
.campaign-touchpoint .campaign-touchpoint-template textarea:focus,
.campaign-touchpoint input:focus {
    outline: none !important;
    resize: none !important;
    border-color: none;
    box-shadow: none;
}

.campaign-touchpoint .campaign-touchpoint-info {
    color: #AAA;
    width: 90%;
    height: 100%;
    padding: 20px;
}

.campaign-touchpoint-name-input-container {
    justify-content: space-between;
    padding: 0px;
}

.campaign-touchpoint .campaign-touchpoint-name {
    color: #38AFFC;
}

.add-touchpoint {
    color: #AAA;
}
.add-touchpoint .textarea {
    border-right: solid 1px #eee;
    color: #AAA;
}

.campaign-touchpoint-message {
    border: none;
    border-top: 1px solid #6c757d;
    border-radius: 0px;
    min-height: 258px;
}

.campaign-touchpoint-message-subject {
    border-radius: 0px;
    border: 0px;
}

.campaign-settings {
    width: 10%;
}

.campaign-touchpoint-template {
    min-height: 100%;
}

.keywords{
    padding:10px;
    background-color:#f9f9f9;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .campaign-touchpoint-message-subject {
        border-top: 1px solid #6c757d
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    .campaign-touchpoint-name-input-container {
        width: 38%;
        border-right: 1px solid #6c757d;
    }

    .campaign-touchpoint-message {
        border-top: 0px solid transparent;
    }

    .campaign-touchpoint-message-subject {
        border-bottom: 1px solid #6c757d;
    }
 }
