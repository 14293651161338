.flyout-container{
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    text-align:center;
}

.flyout-container:hover, .flyout-container:focus{
    text-decoration:none;
    outline:none;
}

.flyout{
    position:absolute;
    z-index:99;
    top:50%;
    left:-280px;

    background-color:white;
    color:lightgrey;
    box-shadow:0 8px 17px 0 rgba(0,0,0,0.14), 0 6px 20px 0 rgba(0,0,0,0.133);
    border: solid 2px #eee;
    padding:10px;
    width:300px;
    text-align:left;
    text-decoration:none;
    transition:all, 0.3s;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.flyout svg{
    color:#38AFFC;
}

.flyout .flyout-link{
    color: #6c757d;
}

.flyout:hover{
    text-decoration:none;
    
}