#mainContainer {
    max-width: 80vw;
}

.headerColor {
    color: rgb(173, 167, 167);
}

.lightColor {
    color: rgb(199, 194, 194);
}

.boldTitles {
    color: #007bff;
    font-weight: bold;
}

.sectionBorder {
    border: 2.5px solid #dee2e6;
    margin: 3px 0px;
}

.msgBtn{
    border-left: 1px solid #dee2e6;
    width: 50px;
}

button {
    float: right;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}